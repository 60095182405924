import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  Divider,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { read, utils } from 'xlsx';
import { loopRawData } from './utilsXlsx';
import DevicesAddRow from './DevicesAddRow';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { useNavigate } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SimpleBackdrop from '../BackLoading';

function ReadXLSX() {
  const articles = useSelector((state) => state.article['xlsxArticles']);
  const xlsxIsLoading = useSelector((state) => state.article.xlsxIsLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = React.useState('');
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    readXLSXFile(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    readXLSXFile(file);
  };

  const readXLSXFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary', cellDates: true });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const sheetData = utils.sheet_to_json(worksheet, { header: 1 });

      if (sheetData.length > 0) {
        dispatch(actions.ARTICLE.addArticlesRMA(loopRawData(sheetData.slice(1))));
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleReset = () => {
    setType('');
    dispatch(actions.ARTICLE.addArticlesRMA([]));
  };

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <SimpleBackdrop open={xlsxIsLoading} />
      <Typography variant="title">XLSX Import</Typography>
      <Divider sx={{ mb: 1 }} />

      {type !== '' && (
        <>
          <div
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{ padding: '20px', border: '2px dashed #ccc' }}
          >
            <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
          </div>
          <Box sx={{ p: 1, maxHeight: 200, overflow: 'auto' }}>
            {articles.length > 0 &&
              articles.map((item, index) => {
                return (
                  <DevicesAddRow
                    key={index + 'ARTICLES-RMA'}
                    actionDelete={() => {
                      dispatch(actions.ARTICLE.removeArticleRMA(index));
                    }}
                    actionEdit={() => {
                      dispatch(actions.CUSTOMERS.changeEditMode());
                    }}
                    article={item}
                    articleXLSXArrIndex={index}
                  />
                );
              })}
          </Box>
        </>
      )}
      <Stack sx={{ mt: 2 }} flexDirection={'row'} justifyContent={'space-between'}>
        <Button
          size="small"
          startIcon={<RestartAltIcon />}
          onClick={handleReset}
          variant="contained"
          sx={{ justifySelf: 'flex-end' }}
          disabled={xlsxIsLoading}
        >
          Reset
        </Button>
        {articles.length > 0 && (
          <Paper sx={{ py: 1, px: 8 }} elevation={4}>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Es wurden {articles.length} Artikel erkannt.
            </Typography>
          </Paper>
        )}
        <FormControl sx={{ width: '180px' }} size="small">
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            error={type === ''}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Type"
            onChange={handleChange}
          >
            <MenuItem value={'GPU'}>GPU</MenuItem>
            <MenuItem value={'Barebone'}>Barebone</MenuItem>
          </Select>
        </FormControl>
        <Button
          startIcon={<FileUploadIcon />}
          size="small"
          onClick={() => {
            if (window.confirm('RMA Generieren?')) {
              if (articles.length > 0) {
                dispatch(actions.ARTICLE.postXLSX(type))
                  .unwrap()
                  .then((res) => {
                    handleReset();
                    navigate(`/customer/${res.customerID}/rma/${res.id}`);
                  });
              } else {
                window.alert('Sie brauchen artikeln hinzüfugen');
              }
            }
          }}
          variant="contained"
          sx={{ justifySelf: 'flex-end' }}
          disabled={xlsxIsLoading}
        >
          Upload
        </Button>
      </Stack>
    </Box>
  );
}

export default ReadXLSX;
